import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "../lottie/loading_anim.json";
import { useNavigate } from "react-router-dom";
export default function Loader() {
  const navigateTo = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultDims = { h: window.innerHeight - 60, w: window.innerWidth };
  const [windowDimensions, setWindowDimensions] = useState(defaultDims);
  useEffect(() => {
    function handleResize() {
      setWindowDimensions({ w: window.innerWidth, h: window.innerHeight - 60 });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      navigateTo("/menu");
    }, 5000);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        height: windowDimensions.h,
        padding: "2rem",
        alignItems: "center",
        backgroundColor:"white"
      }}
    >
      <Lottie options={defaultOptions} height={350} width={350} />
    </div>
  );
}
