import React from "react";
import { RouterProvider } from "react-router-dom";
import AppRoutes from "./routes";
import { useDispatch } from "react-redux";
import { setLoaded } from "./features/globalSlice";
import { setMData } from "./features/menuSlice";
import { loadMenuData } from "./api";
function App() {
  let polltime = 30000;  
  const dispatch = useDispatch();
  loadMenuData().then(data=>{
    dispatch(setMData(data));
    dispatch(setLoaded(true))
  });
  const refreshData =()=>{
    loadMenuData().then(data=>{
      dispatch(setMData(data));
      dispatch(setLoaded(true))
    });
  }
  setInterval(refreshData,polltime);
  return <RouterProvider router={AppRoutes} />;
}
export default App;
