import React from "react";
import {  useState } from "react";
import {Snackbar, Alert } from "@mui/material";
import { Grid, TextField, Button } from "@mui/material";
export default function Login(props) {
   const formValues = {
    email: "",
    passcode: "",
  };
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [formData, setFormData] = useState(formValues);
  const resetForm = () => {
    setFormData({ ...formData, formValues });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.email.length > 0 && formData.passcode.length > 0) {
      let passed = false;
      if (
        formData.email == "admin@conciergett.com" &&
        formData.passcode == "iboss2023"
      ) {
        setIsSuccess(true);
        passed = true;
        setTimeout(()=>{props.loginU(0)},2000);     
      }
      if (
        formData.email == "checker@conciergett.com" &&
        formData.passcode == "checker2023"
      ) {
        setIsSuccess(true);
        passed = true;
        setTimeout(()=>{props.loginU(1)},2000);       
      }
      if (!passed) setIsFail(true);
    } else {
      setIsFail(true);
    }
  };
  const handleInputChange = (e) => {
    let target = e.target.name;

    switch (target) {
      case "emailu":
        setFormData({ ...formData, email: e.target.value });
        break;
      case "passcode":
        setFormData({ ...formData, passcode: e.target.value });
        break;
        default:
          break;
    }
  };
  return (
    <section>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          alignItems="center"
          justify="center"
          direction="column"
          p={4}
        >
          <Grid item width="100%" mb={1.5}>
            <TextField
              style={{ width: "100%" }}
              id="emailu"
              name="emailu"
              label="Email/Username"
              type="text"
              value={formData.emailu}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item width="100%" mb={1.5}>
            <TextField
              fullWidth
              id="passcode"
              name="passcode"
              label="Passcode"
              type="text"
              value={formData.passcode}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid container justifyContent="end" direction="row">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{
                  backgroundColor: "green",
                  margin: "5px",
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      
      <Snackbar
        open={isSuccess}
        autoHideDuration={2000}
        onClose={() => {
          setIsSuccess(false);          
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setIsSuccess(false);            
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          Login Successful!
        </Alert>
      </Snackbar>
      <Snackbar
        open={isFail}
        autoHideDuration={2000}
        onClose={() => setIsFail(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setIsFail(false);
            resetForm();
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          Login failed, check your email or passcode.
        </Alert>
      </Snackbar>
    </section>
  );
}
