import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppBar } from "./components";
import { Paper, Typography } from "@mui/material";
import { setLoggedIn, setRole } from "../features/globalSlice";
import Login from "./components/login";
export default function Landing() {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const isLoaded = useSelector((state) => state.globals.isLoaded);
  const role = useSelector((state) => state.globals.role);
  const isLoggedIn = useSelector((state) => state.globals.isLoggedIn);
  if (!isLoaded) navigateTo("/");

  const defaultDims = { h: window.innerHeight, w: window.innerWidth };
  const [windowDimensions, setWindowDimensions] = useState(defaultDims);
  const loginU = (val) => {
    dispatch(setRole(val));
    dispatch(setLoggedIn(true));
    navigateTo("/main");
  };
  useEffect(() => {
    function handleResize() {
      setWindowDimensions({ w: window.innerWidth, h: window.innerHeight });
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); 
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: windowDimensions.h,
        alignItems: "start",
        backgroundColor: "gray",
      }}
    >
      <AppBar role={role} isLoggedIn={isLoggedIn} />
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          mt: "15vh",
          width: windowDimensions.w * 0.75,
          justifyContent: "center",
          alignItems: "center",
          py: 5,
          mx: 2,
        }}
      >
        <Typography
          align="center"
          noWrap
          sx={{
            fontSize: 24,
            fontWeight: 400,
            px: 4,
            py: 2,
          }}
        >
          Welcome, please login
        </Typography>
        {/* <ButtonGroup
          disableElevation
          variant="contained"
          aria-label="Disabled elevation buttons"
        >
          <Button onClick={handleClick} value={0}>I'm Concierge</Button>
          <Button  onClick={handleClick} value={1}>I'm Checker</Button>
        </ButtonGroup> */}
        <Login loginU={loginU} />
      </Paper>
    </div>
  );
}
