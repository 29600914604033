import React from "react";
import { createBrowserRouter } from "react-router-dom";
import * as Page from '../pages';
const AppRoutes = createBrowserRouter([
    {
        path:'/',
        element:<Page.Loader />
    },
    {
        path:'/menu',
        element:<Page.Landing />
    },
    {
        path:'/main',
        element:<Page.Main />
    },
]);
export default AppRoutes;