import React,{useEffect,useState} from "react";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoggedIn, setRole } from "../features/globalSlice";
import { AppBar, MenuList } from "./components";
export default function Main() {
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const isLoaded = useSelector((state) => state.globals.isLoaded);
    const isLoggedIn = useSelector((state) => state.globals.isLoggedIn);
    const role = useSelector((state) => state.globals.role);
    if (!isLoaded) navigateTo("/");
    if (!isLoggedIn) navigateTo("/menu");
    const defaultDims = { h: window.innerHeight, w: window.innerWidth };
    const [windowDimensions, setWindowDimensions] = useState(defaultDims);
    useEffect(() => {
      function handleResize() {
        setWindowDimensions({ w: window.innerWidth, h: window.innerHeight });
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    const logout = ()=>{
      dispatch(setLoggedIn(false));
      dispatch(setRole(0));
      //navigateTo("/menu");
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: windowDimensions.h,
          alignItems: "start",
          backgroundColor:'gray',          
        }}
      >
      <AppBar logout={logout} role={role}/> 
      <MenuList />
      </div>
    );
}
