import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { setMData } from "../../features/menuSlice";
import { updateStatus } from "../../api";

export default function MenuList() {
    const menuData = useSelector(state => state.menu.data);
    const role = useSelector(state =>state.globals.role);
    const dispatch = useDispatch();   
    const handleCheckBoxClicked = (id, e) => {
        let menuCopy = [...menuData];
        let affectedItem = menuCopy.find(obj => obj.id === id)
        let affectedItemCpy = { ...affectedItem }
        affectedItemCpy.status = affectedItem.status ===1 ? 0 : 1;
        updateStatus(id, affectedItemCpy.status)
        affectedItem = affectedItemCpy;
        menuCopy[id - 1] = affectedItem;
        dispatch(setMData(menuCopy))

    }
    return (
        <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
            {menuData.map((value) => {
                const labelId = `checkbox-list-secondary-label-${value.id}`;
                return (
                    <div key={value.id} >
                        <ListItem
                            secondaryAction={
                                <Checkbox
                                    disabled={role==0?true:false}
                                    edge="end"
                                    onClick={(e) => { handleCheckBoxClicked(value.id, e) }}
                                    checked={value.status === 1}
                                    inputProps={{ "aria-labelledby": labelId }}
                                />
                            }
                            disablePadding
                            sx={{ backgroundColor: value.status === 0 ? 'crimson' : '#d2f8d2', mb: .25 }}
                        >
                            <ListItemButton>
                                {/* <ListItemAvatar>
                <Avatar
                  alt={`Avatar n°${value + 1}`}
                  src={`/static/images/avatar/${value + 1}.jpg`}
                />
              </ListItemAvatar> */}
                                <ListItemText
                                    primary={value.name}
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: "inline" }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {value.category}
                                            </Typography>
                                            {" — " + value.location}
                                        </React.Fragment>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </div>
                );
            })}
        </List>
    );
}
