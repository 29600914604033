import { createSlice } from "@reduxjs/toolkit";

export const globalSlice = createSlice({
  name: "globals",
  initialState: {
    isLoaded: false,
    isLoggedIn:false,
    role:0,
  },
  reducers: {
    setLoaded: (state,action) => {
      state.isLoaded = action.payload;
    },
    getLoaded: (state) => {
      return state.isLoaded;
    },
    setLoggedIn: (state,action) => {
      //console.log("SETTING LOGGED IN TO "+action.payload)
      state.isLoggedIn = action.payload;
    },
    getLoggedIn: (state) => {
      return state.isLoggedIn;
    },
    setRole: (state,action) => {
      //console.log("SETTING ROLE TO "+action.payload)
        state.role =  action.payload;
    },
    getRole: (state) => {
        return state.role;
      },
  },
});
export const { getLoaded, setLoaded, setRole, getRole,getLoggedIn, setLoggedIn, } = globalSlice.actions;
export default globalSlice.reducer;
