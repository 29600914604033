const DB_ENDPOINT = "https://ttconcierge.andestt.biz/_junk.php?";
export const loadMenuData = async () => {
  try {
    let data = await fetch(DB_ENDPOINT + "requestCode=loadMenu");
    return data.json();
  } catch (e) {
    alert("Unable to establish a connection with our servers")
  }
};
export const updateStatus = async (id, val) => {
  try {
    await fetch(
      DB_ENDPOINT + "requestCode=updateStatus&id=" + id + "&val=" + val
    );
    return;
  } catch (e) {
    alert("Unable to establish a connection with our servers")
  }
};

export const generateReport = async () => {
  try {
    await fetch(DB_ENDPOINT + "requestCode=generateSendReport");
    return;
  } catch (e) {
    alert("Unable to establish a connection to our servers")
  }
};
