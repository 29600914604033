import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
  name: "menu",
  initialState: {
    data: [],
  },
  reducers: {
    setMData: (state,action) => {
      state.data = action.payload;
    },
    getMData: (state) => {
        return state.data;
    },
  },
});
export const { getMData, setMData } = menuSlice.actions;
export default menuSlice.reducer;
